
  import { Vue, Component } from 'vue-property-decorator'
  import QuestionCreate from '../components/question/QuestionCreate.vue'
  import { QuestionInterface } from '@/interface/BaseInterface'
  import QuestionApi from '../api/questionApi'
  import getApi from '@/api'

  const questionApi: QuestionApi = getApi('question')

  @Component({
    components: { QuestionCreate }
  })
  export default class CreateQuestionView extends Vue {
    isLoading = false
    isLoading2 = false

    async handleSubmit(data: QuestionInterface) {
      try {
        this.isLoading = true
        if (data._id === '') {
          delete data._id
          await questionApi.create(data)
        } else {
          await questionApi.update(data._id, data)
        }
        this.isLoading = false
        this.$router.push({ name: 'questionBank' })
      } catch (error) {
        this.isLoading = false
      }
    }

    handleCancel() {
      this.$router.push({ name: 'questionBank' })
    }

    async mounted() {
      if (this.questionId) {
        this.isLoading2 = true
        const data = await questionApi.getById(this.questionId)
        if (this.$refs.questionCreate) {
          // @ts-ignore
          this.$refs.questionCreate.setDataEdit(data)
        }
        this.isLoading2 = false
      }
    }
  }
